import React, { useCallback, useMemo, useState } from 'react'

import { Controller, FormProvider, useForm } from 'react-hook-form'

import * as Modal from '_components/modal/generic-modal'
import { Select, DateTimePicker } from '_/components/inputs'
import * as Input from '_/components/inputs/Input'
import { DatePicker, Loader, Button } from '@refera/ui-web'
import SwitchComponent from '_/components/switch'
import { InputLabel, IconButton, InputAdornment } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

import useStyles from './styles'
import {
  castToStringifiedNumber,
  CONTRACT_STATUS_OPTIONS,
  CONTRACT_TYPE_OPTIONS,
  AGENCY_REFERA_DETAILS_FIELDS,
  transformValue,
} from './constants'
import { FormHelperText, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { agencySelector } from '_/modules/agency/selectors'
import { userSelector } from '_/modules/authentication/selectors'
import { getConnectionSelector } from '_modules/provider/selectors'
import { useToast } from '_/hooks/use-toast'
import { updateAgency } from '_/modules/agency/actions'
import moment from 'moment'
import { useDialog } from '_/hooks/use-dialog'

const agencyReferaDetailsFieldTypes = Object.entries(AGENCY_REFERA_DETAILS_FIELDS).reduce(
  (acc, [, value]) => {
    acc[value.name] = value.type
    return acc
  },
  {}
)

const transformPayloadValues = data => {
  const transformedData = Object.entries(data).reduce((acc, [key, value]) => {
    switch (agencyReferaDetailsFieldTypes[key]) {
      case 'number': {
        acc[key] = value ? castToStringifiedNumber(value) : null
        break
      }
      case 'datetime': {
        try {
          if (!value) {
            acc[key] = null
            break
          }

          const formattedDate = moment(value).toISOString()
          acc[key] = formattedDate
        } catch (error) {
          throw new Error('Data inválida')
        }
        break
      }
      case 'date': {
        try {
          if (!value) {
            acc[key] = null
            break
          }
          const formattedDate = moment(value).format('YYYY-MM-DD')
          acc[key] = formattedDate
        } catch (error) {
          throw new Error('Data inválida')
        }
        break
      }
      default: {
        acc[key] = value
        break
      }
    }

    return acc
  }, {})
  return transformedData
}

export function AgencyReferaDetailsModal({ onClose, open, handleGetAgencyInfo }) {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const agency = useSelector(agencySelector)
  const user = useSelector(userSelector)
  const connectionsList = useSelector(getConnectionSelector)
  const { showDialog, closeDialog } = useDialog()

  const [loading, setLoading] = useState(false)

  const renderInput = useCallback(params => <TextField variant="standard" {...params} />, [])

  const defaultValues = useMemo(() => {
    if (!agency) {
      return {}
    }

    const filteredValues = Object.entries(AGENCY_REFERA_DETAILS_FIELDS).reduce(
      (acc, [key, value]) => {
        acc[value.name] = transformValue(agency[value.name], key)
        return acc
      },
      {}
    )

    return filteredValues
  }, [agency])

  const whatsappConnectionOptions = useMemo(
    () => connectionsList?.slice()?.sort((a, b) => a.id - b.id) || [],
    [connectionsList]
  )

  const methods = useForm({
    mode: 'all',
  })

  const { handleSubmit } = methods

  const handleSubmitData = useCallback(
    async data => {
      setLoading(true)

      let transformedPayload

      try {
        transformedPayload = transformPayloadValues(data)
      } catch (error) {
        showToast({
          type: 'error',
        })
        setLoading(false)
        return
      }

      dispatch(updateAgency(transformedPayload, false))
        .then(() => {
          showToast({
            type: 'success',
          })
          handleGetAgencyInfo()
          setLoading(false)
          onClose()
        })
        .catch(() => {
          showToast({
            type: 'error',
          })
          setLoading(false)
        })
    },
    [showToast, dispatch]
  )

  const onSubmit = async data => {
    const changeTypeContractToSAAS =
      agency?.contractType !== 'SAAS' && data?.contractType === 'SAAS'

    const changeTypeContractToNotSAAS =
      agency?.contractType === 'SAAS' && data?.contractType !== 'SAAS'

    if (changeTypeContractToSAAS || changeTypeContractToNotSAAS) {
      showDialog({
        type: 'warning',
        subject:
          'Esta ação irá mudar todos os perfis de acesso dos usuários desta intermediária, tornando-os todos administradores. Você confirma esta ação?',
        labelApprove: 'Sim',
        labelCancel: 'Não',
        onApprove: () => {
          handleSubmitData(data)
          closeDialog()
        },
        onCancel: closeDialog,
      })
    } else {
      handleSubmitData(data)
    }
  }

  const renderContractStatusClearButton = useCallback(() => {
    if (!methods.getValues(AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name)) return null

    return (
      <InputAdornment position="end">
        <IconButton
          onClick={() => methods.setValue(AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name, null)}
          style={{ padding: '4px', marginRight: '20px' }}
        >
          <ClearIcon
            fontSize="small"
            style={{
              fill: '#B4B4B4',
            }}
          />
        </IconButton>
      </InputAdornment>
    )
  }, [methods.watch(AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name)])

  return (
    <Modal.Root open={open} onClose={onClose} maxWidth="md">
      <Modal.TitleModal title="Dados da Refera" />

      <Modal.Content className={styles.modalContent}>
        <FormProvider {...methods}>
          <form className={styles.form} onSubmit={e => e.preventDefault()}>
            <div className={styles.column}>
              <div className={styles.row}>
                <Select
                  label="Tipo de contrato"
                  name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_TYPE.name}
                  options={CONTRACT_TYPE_OPTIONS}
                  getLabel={item => item.label}
                  getValue={item => item.value}
                  defaultValue={agency?.contractType}
                  disabled={user?.activeProfile === 'intermediary'}
                />
                <Select
                  label="Status do contrato"
                  name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name}
                  defaultValue={
                    defaultValues[AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name] ?? null
                  }
                  getLabel={item => item.label}
                  getValue={item => item.value}
                  endAdornment={renderContractStatusClearButton()}
                  options={CONTRACT_STATUS_OPTIONS}
                  disabled={user?.activeProfile === 'intermediary'}
                />
                <Input.Root
                  name={AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT.name}
                  defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT.name]}
                >
                  <Input.Label
                    labelClasses={styles.labelNumberInput}
                    name={AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT}
                  >
                    Limite de VIPs
                  </Input.Label>
                  <Input.ControllerNumber
                    name={AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT.name}
                    decimalScale={0}
                  />
                  <Input.ErrorMessage name={AGENCY_REFERA_DETAILS_FIELDS.VIP_LIMIT.name} />
                </Input.Root>
                <div className={styles.switchInputGroup}>
                  <label
                    className={styles.switchLabel}
                    htmlFor={AGENCY_REFERA_DETAILS_FIELDS.IS_VIP_AGENCY.name}
                  >
                    Agência VIP
                  </label>
                  <Controller
                    control={methods.control}
                    id={AGENCY_REFERA_DETAILS_FIELDS.IS_VIP_AGENCY.name}
                    name={AGENCY_REFERA_DETAILS_FIELDS.IS_VIP_AGENCY.name}
                    defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.IS_VIP_AGENCY.name]}
                    render={props => (
                      <SwitchComponent
                        checked={props.value}
                        onChange={e => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <DateTimePicker
                  label="Data de geracão do contrato"
                  name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_GENERATION_DATE.name}
                  defaultValue={
                    defaultValues[AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_GENERATION_DATE.name] ??
                    null
                  }
                  rules={{}}
                  className={styles.dateTimePicker}
                  referaDatePickerProps={{ disabled: true }}
                />
                <DateTimePicker
                  label="Data de bloqueio por contrato"
                  name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_BLOCK_DATE.name}
                  defaultValue={
                    defaultValues[AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_BLOCK_DATE.name] ?? null
                  }
                  rules={{}}
                  className={styles.dateTimePicker}
                  referaDatePickerProps={{ disabled: user?.activeProfile === 'intermediary' }}
                />
                <div className={styles.datePickerInputGroup}>
                  <InputLabel
                    variant="standard"
                    id={`${AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_SIGNING_DATE.name}-label`}
                    shrink
                    className={styles.datePickerLabel}
                  >
                    Data de assinatura do contrato
                  </InputLabel>
                  <Controller
                    control={methods.control}
                    name={AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_SIGNING_DATE.name}
                    defaultValue={
                      defaultValues[AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_SIGNING_DATE.name] ?? null
                    }
                    render={field => {
                      return (
                        <DatePicker
                          variant="inline"
                          mask="__/__/____"
                          format="dd/MM/yyyy"
                          refuse={/[^\d.]+/gi}
                          renderInput={renderInput}
                          disabled
                          {...field}
                        />
                      )
                    }}
                  />

                  <FormHelperText
                    className={styles.errorMessage}
                    error={!!methods.errors?.contractSignatureDate}
                  >
                    {methods.errors?.contractSignatureDate?.message}
                  </FormHelperText>
                </div>
              </div>

              <div className={styles.row} style={{ paddingTop: '16px' }}>
                <Input.Root
                  name={AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE.name}
                  defaultValue={
                    defaultValues[AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE.name]
                  }
                >
                  <Input.Label
                    labelClasses={styles.labelNumberInput}
                    name={AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE}
                  >
                    Percentual do intermediário
                  </Input.Label>
                  <Input.ControllerNumber
                    name={AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE.name}
                    decimalScale={2}
                  />
                  <Input.ErrorMessage
                    name={AGENCY_REFERA_DETAILS_FIELDS.AGENCY_COMPLETION_PERCENTAGE.name}
                  />
                </Input.Root>

                <Input.Root
                  name={AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name}
                  defaultValue={
                    defaultValues[AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name]
                  }
                >
                  <Input.Label
                    labelClasses={styles.labelNumberInput}
                    name={AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name}
                  >
                    Percentual da Refera
                  </Input.Label>
                  <Input.ControllerNumber
                    name={AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name}
                    decimalScale={2}
                  />
                  <Input.ErrorMessage
                    name={AGENCY_REFERA_DETAILS_FIELDS.REFERA_COMPLETION_PERCENTAGE.name}
                  />
                </Input.Root>

                <Input.Root
                  name={AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name}
                  defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name]}
                >
                  <Input.Label
                    labelClasses={styles.labelNumberInput}
                    name={AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name}
                  >
                    Valor mínimo para venda
                  </Input.Label>
                  <Input.ControllerNumber
                    prefix="R$ "
                    name={AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name}
                  />
                  <Input.ErrorMessage name={AGENCY_REFERA_DETAILS_FIELDS.MINIMUM_SALE_VALUE.name} />
                </Input.Root>
                {user?.activeProfile === 'refera' && (
                  <Select
                    label="WhatsApp do vendedor"
                    name={AGENCY_REFERA_DETAILS_FIELDS.SELLER_CONNECTION.name}
                    options={whatsappConnectionOptions}
                    getLabel={item => item.phone}
                    getValue={item => item.id}
                    defaultValue={
                      defaultValues[AGENCY_REFERA_DETAILS_FIELDS.SELLER_CONNECTION.name]
                    }
                  />
                )}
              </div>

              <div className={styles.row} style={{ paddingTop: '18px' }}>
                <div className={styles.switchInputGroup}>
                  <label
                    className={styles.switchLabel}
                    htmlFor={AGENCY_REFERA_DETAILS_FIELDS.SEND_NOTIFICATION.name}
                  >
                    Enviar Notificação
                  </label>
                  <Controller
                    control={methods.control}
                    name={AGENCY_REFERA_DETAILS_FIELDS.SEND_NOTIFICATION.name}
                    defaultValue={
                      defaultValues[AGENCY_REFERA_DETAILS_FIELDS.SEND_NOTIFICATION.name]
                    }
                    render={props => (
                      <SwitchComponent
                        checked={props.value}
                        onChange={e => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>

                <div className={styles.switchInputGroup}>
                  <label
                    className={styles.switchLabel}
                    htmlFor={AGENCY_REFERA_DETAILS_FIELDS.DEFINE_RESPONSIBLE.name}
                  >
                    Definir responsável
                  </label>
                  <Controller
                    control={methods.control}
                    name={AGENCY_REFERA_DETAILS_FIELDS.DEFINE_RESPONSIBLE.name}
                    defaultValue={
                      defaultValues[AGENCY_REFERA_DETAILS_FIELDS.DEFINE_RESPONSIBLE.name]
                    }
                    render={props => (
                      <SwitchComponent
                        checked={props.value}
                        onChange={e => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>

                <div className={styles.switchInputGroup}>
                  <label
                    className={styles.switchLabel}
                    htmlFor={AGENCY_REFERA_DETAILS_FIELDS.REVIEW_BUDGET.name}
                  >
                    Revisar orçamento
                  </label>
                  <Controller
                    control={methods.control}
                    name={AGENCY_REFERA_DETAILS_FIELDS.REVIEW_BUDGET.name}
                    defaultValue={defaultValues[AGENCY_REFERA_DETAILS_FIELDS.REVIEW_BUDGET.name]}
                    render={props => (
                      <SwitchComponent
                        checked={props.value}
                        onChange={e => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>

                <div className={styles.switchInputGroup}>
                  <label
                    className={styles.switchLabel}
                    htmlFor={AGENCY_REFERA_DETAILS_FIELDS.ALLOWS_RENT_DISCOUNT.name}
                  >
                    Permite desconto de aluguel
                  </label>
                  <Controller
                    control={methods.control}
                    name={AGENCY_REFERA_DETAILS_FIELDS.ALLOWS_RENT_DISCOUNT.name}
                    defaultValue={
                      defaultValues[AGENCY_REFERA_DETAILS_FIELDS.ALLOWS_RENT_DISCOUNT.name]
                    }
                    render={props => (
                      <SwitchComponent
                        checked={props.value}
                        onChange={e => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </Modal.Content>

      <Modal.Actions>
        {methods.watch(AGENCY_REFERA_DETAILS_FIELDS.CONTRACT_STATUS.name) !== 'cancelled' && (
          <Button variant="ghost" onClick={() => {}} className={styles.redButton}>
            Solicitar rescisão
          </Button>
        )}
        <Modal.ButtonRed onClick={onClose} disabled={loading}>
          Cancelar
        </Modal.ButtonRed>
        <Modal.ButtonFullBlue onClick={handleSubmit(onSubmit)} disabled={loading}>
          Salvar
        </Modal.ButtonFullBlue>
      </Modal.Actions>
      <Loader hasBackdrop open={loading} />
    </Modal.Root>
  )
}
