import { useParams } from '@reach/router'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WhatsApp } from '@material-ui/icons'

import useToggle from '_hooks/use-toggle'
import useBudgetPdfFileName from '_hooks/use-budget-pdf-file-name'
import { sendBudgetWhatsapp } from '_modules/budget/actions'
import { getBudgetByIdSelector } from '_modules/budget/selectors'
import Button from '_components/button'
import ModalDialog, { WARNING_MODAL } from '_components/modal/modal-dialog'
import { userSelector } from '_modules/authentication/selectors'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { formatAddress } from '_utils/helpers'
import { formatMessage, formatMessageIntermediary } from './messageWhatsApp'

import ConfirmModal from '../modals/confirm-modal'

import useStyles from './styles'
import { getHistoryLogs } from '_modules/history-logs/actions'
import { Alert, Toast } from '@refera/ui-web'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const SendBudgetByWhatsAppButton = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { budgetId, serviceOrderId } = useParams()
  const { showToast } = useToast()
  const { checkUserPermission } = useRolePermission()

  const user = useSelector(userSelector)
  const budget = useSelector(getBudgetByIdSelector(budgetId))

  const serviceOrder = useSelector(currentServiceOrderSelector)

  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [isRefusedWhatsApp, setIsRefusedWhatsApp] = useToggle()
  const [isConfirmSendBudget, setIsConfirmSendBudget] = useToggle()
  const [isNotRevised, setIsNotRevised] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const pdfFileName = useBudgetPdfFileName({ budgetId })

  const handleSendBudgetWhatsApp = useCallback(() => {
    const budgetPayer = budget.budgetPayer.toJS()[0]
    const budgetRevised = budget.isRevised || budget.proposeOpened
    if (!budgetPayer?.whatsappResponsible || !budgetPayer?.personPayingEmail) {
      setModalDialog({
        isOpen: true,
        subTitle:
          'Não foi digitado o WhatsApp e/ou e-mail do responsável pelo pagamento.\n' +
          '        Clique em "Dados do pagador" e digite esta informação.',
        type: WARNING_MODAL,
      })
      return
    }
    if (budget.refused) {
      setIsRefusedWhatsApp()
    }
    if (!budgetRevised) {
      setIsNotRevised(true)
    } else {
      setIsConfirmSendBudget()
    }
  }, [budget.budgetPayer, budget.refused])

  const sendBudgetByWhatsApp = useCallback(() => {
    const address = formatAddress(serviceOrder.property, false, true, true)

    const paymentMethod =
      budget?.get('maxInstallmentsAllowed') > 0
        ? `parcelado em até ${budget?.get('maxInstallmentsAllowed')}x`
        : `à vista`
    const acceptsWarranty = budget?.get('acceptsWarranty')
      ? `tem garantia de ${budget?.get('warrantyMonths')} meses`
      : `não tem garantia`

    const userRoles = Array.isArray(user.getRoles)
      ? user.getRoles.find(element => element === 'cs_refera')
      : user.getRoles

    const haveOrNotHaveTradesman = serviceOrder?.tradesman
      ? 'Nós então solicitamos a avaliação de um profissional, ele fez uma análise do problema e apontou uma solução.'
      : 'Frente a situação solicitamos a avaliação de um profissional e ele nos passou o seguinte orçamento:'

    const parametros = {
      responsibleName: budget.budgetPayer?.toJS()[0].responsibleName,
      referaName: user.name,
      agencyName: serviceOrder?.agency.get('name'),
      address,
      serviceOrderDescription: serviceOrder.description,
      totalBudget: budget?.get('totalPrice'),
      paymentMethod,
      warranty: acceptsWarranty,
      haveOrNotHaveTradesman,
    }
    const textVersionFinalFinalFinal =
      userRoles === 'cs_refera' ? formatMessage(parametros) : formatMessageIntermediary(parametros)

    const encodedText = window.encodeURIComponent(textVersionFinalFinalFinal)
    const celular = budget.personPayingWhatsapp
      ? `55${budget.personPayingWhatsapp?.replace(/[^A-Z0-9]/gi, '')}`
      : `55${budget.budgetPayer?.toJS()[0]?.whatsappResponsible?.replace(/[^A-Z0-9]/gi, '')}`

    window.open(`https://web.whatsapp.com/send?phone=${celular}&text=${encodedText}`, '_blank')
  }, [budget.budgetPayer, budget.id, budget.personPayingWhatsapp, serviceOrder])

  const handleConfirmSendBudgetByWhatsApp = useCallback(async () => {
    if (isConfirmSendBudget) {
      setIsLoading(true)
      await dispatch(sendBudgetWhatsapp(serviceOrderId, budgetId))
        .then(() => {
          dispatch(getHistoryLogs(serviceOrderId))
          showToast({ type: 'success' })
          setIsLoading(false)
          sendBudgetByWhatsApp()
        })
        .catch(error => {
          const errorMessage = formatErrorMessage(error)
          showToast({ message: errorMessage, type: 'error' })
          setIsLoading(false)
        })
    }
  }, [budgetId, sendBudgetByWhatsApp, isConfirmSendBudget, serviceOrderId])

  const handleSendRefusedBudgetByWhatsApp = useCallback(() => {
    sendBudgetByWhatsApp()
    setIsRefusedWhatsApp()
  }, [sendBudgetByWhatsApp])

  return (
    <>
      <Button
        variant="contained"
        onClick={() =>
          checkUserPermission({
            permission: PERMISSIONS.SEND_BUDGET_TO_PAYER,
            permissionAction: PERMISSIONS_ACTIONS.ADD,
            functionAccess: handleSendBudgetWhatsApp,
          })
        }
        className={styles.whatsButton}
        isLoading={isLoading}
      >
        <WhatsApp className={styles.iconWhatsApp} /> Enviar por WhatsApp
      </Button>

      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
      {isRefusedWhatsApp && (
        <ConfirmModal
          handleModal={setIsRefusedWhatsApp}
          handleSubmit={handleSendRefusedBudgetByWhatsApp}
        >
          Este orçamento está recusado. Você tem certeza que ainda assim deseja enviar este
          orçamento ao pagador?
        </ConfirmModal>
      )}
      {isConfirmSendBudget ? (
        <ConfirmModal
          handleModal={setIsConfirmSendBudget}
          handleSubmit={handleConfirmSendBudgetByWhatsApp}
          fileName={pdfFileName}
          budget={budget}
        >
          Você tem certeza que deseja enviar o orçamento?
        </ConfirmModal>
      ) : (
        isNotRevised && (
          <Toast
            draggable
            open={isNotRevised}
            autoHideDuration={2000}
            onClose={() => setIsNotRevised(false)}
          >
            <Alert
              severity="error"
              title="Você não pode enviar este orçamento porque ele não está revisado. Marque o orçamento como revisado e repita a operação."
              onClose={() => setIsNotRevised(false)}
            />
          </Toast>
        )
      )}
    </>
  )
}

export default SendBudgetByWhatsAppButton
