import React, { useCallback, useMemo, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { navigate, useParams } from '@reach/router'
import { Button } from '@refera/ui-web'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { getOriginalBudget, getStepStatusLogSelector } from '_modules/budget/selectors'
import { useIsCurrentBudgetTradesman } from '_hooks/budget/use-is-current-budget-tradesman'
import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'
import { BUTTONS_ACTION, STEP_STATUS } from '_utils/constants/service-order'
import ModalDialog from '_components/modal/modal-dialog'
import { BudgetContext, UPDATE_BUDGET_TYPE } from '_utils/budget'

import EditPreApprovedBudgetModal from '../../modals/edit-pre-approved-budget-modal'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { useDialog } from '_/hooks/use-dialog'

const SendBudgetButton = () => {
  const { budgetId } = useParams()
  const { isTradesman, isSAAS, checkUserPermission } = useRolePermission()
  const { showDialog, closeDialog } = useDialog()

  const budget = useSelector(getOriginalBudget(budgetId))?.toJS()
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const isCurrentTradesman = useIsCurrentBudgetTradesman(getOriginalBudget(budgetId))

  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [isSendBudgetModalOpen, toggleSendBudgetModalOpen] = useToggle()
  const { handleUpdateBudgetItems, setUpdateBudgetType, setSendBudget, isEditing, isLoading } =
    useContext(BudgetContext)
  const canSendBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.PROVIDER_SEND_BUDGET,
    isBudgetRelated: true,
  })
  const lastStepStatusLogWithBudget = useMemo(
    () => stepStatusLog.filter(log => Boolean(log.budgetId))[0],
    [stepStatusLog]
  )

  const isProposeOpened = useMemo(() => budget?.proposeOpened, [budget?.proposeOpened])

  const label = useMemo(() => (isProposeOpened ? 'Aceitar e enviar' : 'Enviar'), [isProposeOpened])

  const isSendBudgetButtonVisible = useMemo(
    () => ((isTradesman && isCurrentTradesman) || isSAAS) && canSendBudget && isEditing,
    [canSendBudget, isCurrentTradesman, isTradesman, isSAAS, isEditing]
  )

  const handleBackNavigation = useCallback(() => {
    navigate(-1)
  }, [])

  const handleSave = useCallback(() => {
    setUpdateBudgetType(UPDATE_BUDGET_TYPE.DEFAULT)
    handleUpdateBudgetItems()
    setSendBudget(true)
  }, [handleUpdateBudgetItems, setUpdateBudgetType])

  const handleSendBudgetButtonClick = useCallback(() => {
    const shouldOpenSendBudgetModal =
      lastStepStatusLogWithBudget?.stepStatus ===
      STEP_STATUS.WAITING_PRE_APPROVED_PROPOSAL_ACCEPTANCE

    // NOTE: When the service_order is waiting_budget or waiting_budget_schedule, there's no step log with a budget id,
    // so we have to verify the serviceOrder's stepStatus even if this button is related to budget
    const shouldOpenConfirmationModal =
      STEP_STATUS.WAITING_SERVICE_ORDER_ACCEPTANCE === lastStepStatusLogWithBudget?.stepStatus ||
      [STEP_STATUS.WAITING_BUDGET, STEP_STATUS.WAITING_BUDGET_SCHEDULE].includes(
        serviceOrder?.stepStatus
      )

    if (shouldOpenSendBudgetModal) {
      toggleSendBudgetModalOpen()
      return
    }

    if (shouldOpenConfirmationModal) {
      showDialog({
        type: 'warning',
        subject: 'Atenção!',
        description: 'Você tem certeza que deseja executar esta ação?',
        onApprove: () => {
          closeDialog()
          handleSave()
        },
        labelApprove: 'Ok',
        labelCancel: 'Cancelar',
        onCancel: closeDialog,
      })
    }
  }, [lastStepStatusLogWithBudget?.stepStatus, toggleSendBudgetModalOpen, serviceOrder, handleSave])

  function handleSendBudget() {
    if (isTradesman || isSAAS) {
      // checar permission ORIGINAL_BUDGETS
      checkUserPermission({
        permission: PERMISSIONS.ORIGINAL_BUDGETS,
        permissionAction: PERMISSIONS_ACTIONS.ADD,
        functionAccess: handleSendBudgetButtonClick,
      })
    } else {
      checkUserPermission({
        permission: PERMISSIONS.INTERNAL_BUDGETS,
        permissionAction: PERMISSIONS_ACTIONS.ADD,
        functionAccess: handleSendBudgetButtonClick,
      })
    }
  }

  if (!isSendBudgetButtonVisible) {
    return null
  }

  return (
    <>
      <Button onClick={handleSendBudget} color="primary" variant="primary" disabled={isLoading}>
        {label}
      </Button>

      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />

      <EditPreApprovedBudgetModal
        isOpen={isSendBudgetModalOpen}
        handleClose={toggleSendBudgetModalOpen}
        handleConfirm={handleBackNavigation}
        shouldSendBudget
      />
    </>
  )
}

export default SendBudgetButton
