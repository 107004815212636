import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Grid } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Modal, Button } from '@refera/ui-web'
import {
  getCategoryList,
  deleteCategory,
  updateCategory,
  getCategoryById,
  postCategory,
  getServiceList,
} from '_/modules/service-orders/actions'
import Loading from '_components/loading'
import { useToast } from '_/hooks/use-toast'

import useStyles from './styles'
import DynamicTreeView from '_/components/treeview'
import CategoryEdit from './category-edit'
import CategoryCreate from './category-create'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

const CategoryScreen = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()

  const { checkIfUserDoesNotPermission } = useRolePermission()

  const [deleteModal, setDeleteModal] = useState(false)

  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [category, setCategory] = useState()
  const [categoryList, setCategoryList] = useState()
  const [services, setServicesList] = useState()

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    defaultValues: {
      name: category?.name || '',
      allowsNoAttachments: category?.allowsNoAttachments || false,
      allowsTheDistance: category?.allowsTheDistance || false,
      allowsBypass: category?.allowsBypass || true,
      budgeteerMeets: category?.budgeteerMeets || false,
      isActive: category?.isActive || false,
      services: category?.services || [],
    },
    mode: 'all',
  })

  const data = watch()

  const message = {
    title: 'Você tem certeza que deseja executar esta ação?',
    error:
      'Você não pode excluir esta opção porque ela tem itens filhos ou já foi utilizada. Tente desativá-la.',
    duplicationError: 'Conteúdo já existente.',
    success: 'Ação executada com sucesso.',
  }

  const openDeleteModal = () => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.CATEGORIES, PERMISSIONS_ACTIONS.DELETE)) {
      return
    }

    setDeleteModal(true)
  }

  const handleSelection = useCallback(
    e => {
      setIsCreating(false)
      dispatch(getCategoryById(e?.id)).then(res => {
        setCategory(() => (res.count ? {} : res))
      })
    },
    [dispatch, category]
  )

  const handleCreate = () => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.CATEGORIES, PERMISSIONS_ACTIONS.ADD)) {
      return
    }

    setIsCreating(true)

    if (!category) {
      setCategory({})
    }

    reset({
      name: '',
      isActive: false,
      allowsNoAttachments: false,
      allowsTheDistance: false,
      budgeteerMeets: false,
      services: [],
    })
  }

  const handleDelete = async () => {
    setIsDeleting(true)
    await Promise.resolve(dispatch(deleteCategory(category?.id)))
      .then(() => setIsUpdating(true))
      .catch(() =>
        showToast({
          message: message.error,
          type: 'error',
        })
      )
      .then(() => {
        setIsDeleting(() => false)
        showToast({
          message: message.success,
          type: 'success',
        })
      })
    setDeleteModal(() => false)
  }

  const handleUpdate = async payload => {
    if (
      checkIfUserDoesNotPermission(PERMISSIONS.CATEGORIES, [
        PERMISSIONS_ACTIONS.ADD,
        PERMISSIONS_ACTIONS.EDIT,
      ])
    ) {
      return
    }

    const serviceIDArray = payload?.services?.map(item => item.id)

    const request = payload
    request.services = serviceIDArray

    await Promise.resolve(dispatch(updateCategory(category?.id, request)))
      .then(() => setIsUpdating(true))
      .catch(error => {
        showToast({
          message: error?.error_message ?? message.error,
          type: 'error',
        })
      })
      .then(() => {
        showToast({
          message: message.success,
          type: 'success',
        })
      })
  }

  const handlePost = async payload => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.CATEGORIES, PERMISSIONS_ACTIONS.ADD)) {
      return
    }

    const serviceIDArray = payload?.services.map(item => item.id)

    const request = payload
    request.services = serviceIDArray

    await Promise.resolve(dispatch(postCategory({ ...request, parent: category.id })))
      .then(() => setIsUpdating(true))
      .catch(error =>
        showToast({
          message: error?.error_message ?? message.error,
          type: 'error',
        })
      )
      .then(() => {
        showToast({
          message: message.success,
          type: 'success',
        })
      })
  }

  useEffect(() => {
    dispatch(getCategoryList()).then(res => setCategoryList(() => res))
    dispatch(getServiceList()).then(res => setServicesList(res))
  }, [])

  useEffect(() => {
    // UPDATES TREE VIEW AFTER CATEGORIES ARE SAVED/DELETED
    if (isUpdating) {
      dispatch(getCategoryList()).then(res => {
        setIsUpdating(() => false)
        setIsCreating(() => false)
        setCategoryList(res)
        setCategory()
      })
    }
  }, [isUpdating])

  const categoryForms = useMemo(() => {
    if (isCreating && category) {
      return (
        <CategoryCreate
          errors={errors}
          data={data}
          reset={reset}
          control={control}
          setValue={setValue}
          services={services}
          register={register}
          handleSubmit={handleSubmit}
          handlePost={handlePost}
        />
      )
    }

    if (!isCreating && category) {
      return (
        <CategoryEdit
          errors={errors}
          data={data}
          reset={reset}
          control={control}
          category={category}
          services={services}
          register={register}
          setValue={setValue}
          handleUpdate={handleUpdate}
          handleSubmit={handleSubmit}
        />
      )
    }

    return (
      <Grid className={styles.selectWrapper}>
        <Typography className={styles.selectTitle}>Selecione uma categoria.</Typography>
      </Grid>
    )
  }, [category, isCreating, categoryList, register, control, data, reset, control, errors])

  if (!categoryList) {
    return <Loading />
  }

  return (
    <Grid className={styles.container}>
      <Grid className={styles.header}>
        <Typography variant="h4" component="h1" className={styles.title}>
          Categorias
        </Typography>
        <Grid className={styles.buttonWrapper}>
          <Button onClick={() => handleCreate()}>Novo</Button>
          <Button color="red" disabled={!category?.id || isDeleting} onClick={openDeleteModal}>
            Excluir
          </Button>
        </Grid>
      </Grid>

      <Grid className={styles.main}>
        <Grid className={styles.treeWrapper}>
          <DynamicTreeView
            data={categoryList}
            handleClick={handleSelection}
            setState={setCategory}
          />
        </Grid>
        <Grid className={styles.displayWrapper}>{categoryForms}</Grid>
      </Grid>

      <Modal
        className={styles.modal}
        title="Excluir categoria"
        onBackdropClick={() => setDeleteModal(false)}
        onClose={() => setDeleteModal(false)}
        open={deleteModal}
        actionsButtons={
          <Grid className={styles.modalButtons}>
            <Button color="red" variant="secondary" onClick={() => setDeleteModal(false)}>
              Não
            </Button>
            <Button color="primary" variant="primary" onClick={handleDelete}>
              Sim
            </Button>
          </Grid>
        }
      >
        <Typography>{message.title}</Typography>
      </Modal>
    </Grid>
  )
}

export default CategoryScreen
